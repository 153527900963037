// islands/ServerRun/ServerRun.tsx
import Accordion from "../Accordion.tsx";
import ServerRunREST from "./ServerRunREST.tsx";
import { selectedServerID, allAccordionOpen } from '../globalSignals.ts';

export default function ServerRun() {
  return (
    <div class="container mx-auto p-2">
      <Accordion title="Servers Test-Runs" allOpen={allAccordionOpen.value}>
        <ServerRunREST selectedServerID={selectedServerID.value} />
      </Accordion>
    </div>
  );
}
